
export default {
	name: "CoreBlockContainerAccordion",
	props: {
		isShowingContents: {
			type: Boolean,
			default: true,
		},
		settings: {
			type: Object,
			required: true,
		},
	},
	computed: {
		accordionLabel() {
			let label = this.settings?.accordionText || "";

			// for old labels, if it's not wrapped in HTML tags wrap it in an H5 tag
			if (!/<\/?[a-z][\s\S]*>/i.test(label)) label = `<h5>${label}</h5>`;

			// if it's an empty html tag return a default label
			return label.replaceAll(/<[^>]*>/g, "")
				? label
				: "<h5>Accordion</h5>";
		},
	},
};
